
import { Topic } from '@/models';
import Routes from '@/router/Routes';
import { formatCategory, formatPhase } from '@/services/FormatService';
import store from '@/store';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  props: {
    topic: {
      type: Object as PropType<Topic>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const events = ref();
    const loading = ref(false);
    const currentCourse = computed(() => {
      return store.getters['courseModule/currentCourse'];
    });

    watch(() => route.params.topicName, fetchTopic);

    watch(() => props.topic.events, fetchEvents);

    onMounted(() => {
      fetchEvents();
    });

    async function fetchEvents() {
      loading.value = true;
      try {
        const promises = props.topic.events.map((event) => {
          return store.dispatch('eventModule/fetchSyllabusEvent', {
            name: event,
            syllabusId: currentCourse.value.downloadId
          });
        });
        const results = await Promise.all(promises);
        events.value = results.filter((event) => {
          return !!event;
        });
      } catch (e) {
        throw new Error(`error fetching event data: ${e}`);
      }
      loading.value = false;
    }

    async function fetchTopic() {
      await store.dispatch('syllabusModule/fetchTopic', {
        syllabus: store.getters['syllabusModule/syllabus'],
        chapterName: route.params.chapterName,
        topicName: route.params.topicName
      });
    }

    async function handleEventClick(eventName: string) {
      router.push({
        name: Routes.SYLLABUS_TOPIC_EVENT_DETAILS,
        params: {
          topicName: route.params.topicName,
          chapterName: route.params.chapterName,
          eventName
        }
      });
    }

    return {
      events,
      loading,
      formatCategory,
      formatPhase,
      handleEventClick,
      route
    };
  }
});
